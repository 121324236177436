@import '../../Assets/sass/settings/settings'
@import "../../Assets/sass/tools/breakpoints"
@import "../../Assets/sass/tools/mixins"

.Footer
	padding-top: 65px
	padding-bottom: 65px
	background: darken($color-blue2, 25%)
	&__companyName
		@include text-style(private)
		color: $color-brand-green
		text-transform: uppercase
		margin-bottom: 10px
		font-size: 28px
		@include media-breakpoint-down(md)
			text-align: center

	&__adress
		@include text-style(private)
		color: darken($color-basic-white, 30%)
		font-size: 20px
		margin-bottom: 40px
		@include media-breakpoint-down(md)
			margin-bottom: 30px
			text-align: center
		
		
		&-item
			display: block
			padding-top: 5px
	&__map>div
		border-radius: 10px
		overflow: hidden
	&__form
		display: flex
		width: 100%
		gap: 10px
		flex-direction: column
		margin-top: -10px
		position: relative
		&-button
			margin-top: 20px
	
