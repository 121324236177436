@import "../../Assets/sass/settings/settings"
@import "../../Assets/sass/tools/breakpoints"

.CategoryList
	width: 100%
	display: flex
	flex-direction: column
	gap: 10px
	&__item
		width: 100%
		padding: 15px 30px
		display: block
		border: 1px solid lighten($color-blue3, 50%)
		color: $color-text-blue_dark
		font-weight: 700
		line-height: 1.15
		letter-spacing: .5px
		text-transform: uppercase
		font-size: 30px
		text-decoration: none
		box-shadow: 0 3px 5px 0 #ced0d0
		border-radius: 5px
		@include media-breakpoint-down(md)
			font-size: 24px
		&:hover
			background: lighten($color-brand-orange, 20%)
		