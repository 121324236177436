@import "../../Assets/sass/settings/settings"
@import "../../Assets/sass/tools/breakpoints"

.MainProduction
	padding: 80px 0 150px
	&__title
		color: $color-text-blue_dark
		text-align: center
		margin-bottom: 110px
		font-size: 45px
		font-weight: 800
	&__el
		background-blend-mode: color
		text-decoration: none
		transition: all 0.3s
		border-radius: 5px
		box-shadow: 0 1px 8px 0px rgba($color-blue3,.36)
		height: 300px
		background: lighten($color-blue3, 50%)
		position: relative
		@include media-breakpoint-down(md)
			margin-bottom: 30px
		&:hover
			box-shadow: unset
		&-img
			position: absolute
			top: 0
			left: 0
			right: 0
			bottom: 0
			display: flex
			align-items: center
			justify-content: center
			& img
				width: 70%
				height: auto
				max-height: 90%
		&-link
			display: flex
			align-items: flex-end
			justify-content: center
			width: 100%
			height: 100%
			position: relative
			z-index: 1
			transition: all 0.3s
			background: linear-gradient(rgba(0, 0, 0, 0.0) 0%, rgba(0, 21, 63, 0.8) 100%)
			color: #fff
			text-transform: uppercase
			font-size: 30px
			text-decoration: none
			text-align: center
			padding-bottom: 60px
			text-shadow: 0 2px 3px rgb(15 1 1 / 40%)
			@include media-breakpoint-down(lg)
				font-size: 25px
			&:hover
				padding-bottom: 15px
				background: linear-gradient(rgba(0, 0, 0, 0.0) 0%, rgba(0, 21, 63, 0.4) 70%, rgba(0, 21, 63, 0.4) 70%, rgba(0, 21, 63, 0.8) 100%)
