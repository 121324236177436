@import "../../Assets/sass/settings/settings"
@import "../../Assets/sass/tools/breakpoints"

.ProductList
	&__title
		color: #565656
		text-transform: uppercase
		margin-bottom: 20px
		font-size: 28px
		font-weight: 700
		@include media-breakpoint-down(sm)
			font-size: 24px
	&__main
		display: grid
		grid-row-gap: 30px
		grid-column-gap: 20px
		grid-template-columns: repeat(3, 1fr)
		grid-auto-rows: 1fr
		@include media-breakpoint-down(lg)
			grid-template-columns: repeat(2, 1fr)
		@include media-breakpoint-down(sm)
			grid-template-columns: 1fr
		&-item
			padding: 0 0 10px
			border-radius: 10px
			box-shadow: 0 1px 8px 1px #ced0d0
			display: flex
			flex-direction: column
			justify-content: space-between
			gap: 60px
			border: 1px solid transparent
			&:hover
				box-shadow: unset
				border-color:  #ced0d0
			&-top
			&-bottom
				width: 100%
				padding: 0 30px
			&-img
				padding: 15px 10px
				background: #efefef
				width: 100%
				display: flex
				align-items: center
				justify-content: center
				height: 250px
				border-radius: 10px 10px 0 0
				& img
					height: auto
					max-height: 100%
					object-fit: contain
			&-title
				width: 100%
				font-size: 25px
				margin-top: 20px
				padding: 0 15px
				font-weight: 700
				color: $color-text-blue_dark
				text-align: center
			&-btn
				text-transform: uppercase