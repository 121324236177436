.InputField
	width: 100%
	&__label
		font-weight: 400
		font-size: 14px
		line-height: 1.15
		text-transform: uppercase
		margin-bottom: 5px
		display: block
		padding-left: 2px
		opacity: .7
		color: white
	&__input
		box-shadow: inset 0 -3px 11px #e0e0e0
		border: 1px solid #ced0d0
		border-radius: 5px
		font-size: 16px
		padding: 10px
		width: 100%
	&__textarea
		height: 80px
		max-width: 100%
		min-width: 100%
		