.ModalRules
	width: 740px
	padding: 20px
	background: white
	border-radius: 6px
	&__title
		font-size: 23px
		text-transform: uppercase
		text-align: center
		margin-bottom: 20px
		font-weight: 600
	&__text
		margin-bottom: 15px
		&:last-of-type
			margin-bottom: 0
	&__ul
		padding-left: 25px
		list-style: disc
		margin-bottom: 15px
		display: flex
		flex-direction: column
		gap: 7px