@import "../../Assets/sass/settings/settings"
@import "../../Assets/sass/tools/breakpoints"

.MainList
	width: 100%
	padding: 0 0 80px
	&__title
		font-size: 48px
		color: #565656
		margin-bottom: 50px
		@include media-breakpoint-down(md)
			font-size: 40px
		@include media-breakpoint-down(sm)
			font-size: 34px
	&__main
		display: flex
		gap: 40px
		width: 100%
		@include media-breakpoint-down(md)
			flex-direction: column
		&-left
			width: 300px
			flex-shrink: 0
			@include media-breakpoint-down(md)
				width: 100%
				margin-bottom: 30px
		&-right
			flex: 1
	&__menu
		display: flex
		flex-direction: column
		gap: 10px
		min-height: 400px
		@include media-breakpoint-down(md)
			min-height: max-content
		&-itemWrapper
		&-item
			width: 100%
			text-decoration: none
			display: flex
			justify-content: space-between
			align-items: center
			padding: 15px 10px
			color: #535353
			font-size: 20px
			border: 1px solid #d8d7d7
			background: #efefef
			border-radius: 6px
			&:hover, &_active
				background: #d8d7d7