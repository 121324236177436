@import "../../Assets/sass/settings/settings"
@import "../../Assets/sass/tools/mixins"
@import "../../Assets/sass/tools/breakpoints"
@import "../../Assets/sass/objects/buttons"

$headerTopHeight: 26px

.Header
	width: 100%
	margin-top: $headerTopHeight
	&__top
		padding-top: 6px
		padding-bottom: 6px
		text-align: right
		z-index: 120
		position: fixed
		top: 0
		left: 0
		background: $color-grayscale_light
		right: 0
		height: $headerTopHeight
		display: flex
		align-items: center
		&-block
			@include text-style(chips)
			color: $color-basic-white
			@include media-breakpoint-down(xs)
					text-align: center
			justify-content: flex-end
			display: flex
			align-items: center
			gap: 15px
			&-link
				color: lighten($color-basic-black, 20%)
				display: flex
				align-items: center
				gap: 5px
				text-decoration: none
				width: max-content
				&:hover
					text-decoration: underline
				@include media-breakpoint-down(xs)
					margin-left: 0
					padding: 0 5px
			&-text
				@include text-style(private)
				text-transform: none
				font-size: $font-size-smaller
				color: lighten($color-basic-black, 20%)
				@include media-breakpoint-down(xs)
					display: none
	&__bottom
		padding-top: 10px
		padding-bottom: 10px
		box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1)
		position: relative
		background: rgba(255,255,255,0.95)
		&-logo
			display: inline-block
			text-decoration: none
			height: 30px
			@include media-breakpoint-up(md)
				height: 42px
			@include media-breakpoint-up(lg)
				height: 55px
			&-img
				width: 100%
				height: auto
				max-height: 100%
		&-nav
			text-align: center
			@include media-breakpoint-down(md)
				display: none
				position: fixed
				background: rgba(255,255,255,0.95)
				left: 0
				right: 0
				bottom: 0
				padding-top: 20px
				&-open
					display: block
			&-li
				display: inline-block
				margin: 0 12px
				@include media-breakpoint-down(md)
					margin: 15px 0
					width: 100%
					display: flex
					justify-content: center
			&-link
				text-decoration: none
				@include text-style(private)
				color: $color-basic-black
				transition: all 0.2s
				text-transform: uppercase
				font-size: 15px
				@include media-breakpoint-down(md)
					font-size: 24px
				&:hover
					color: $color-blue3
					text-decoration: underline
		&-call
			text-align: center
			@include media-breakpoint-down(md)
				margin-top: 20px
			&-button
				text-transform: uppercase
				font-weight: 500
				min-height: 45px
				max-width: 200px
				margin: 0 auto
				background: $color-brand-orange
				border-color: $color-brand-orange
				display: flex
				align-items: center
				gap: 10px
				&:hover
					background: darken($color-brand-orange, 10%)
					border-color: darken($color-brand-orange, 10%)
				&:active, &:focus
					background: darken($color-brand-orange, 20%)
					border-color: darken($color-brand-orange, 20%)
		&-bar
			text-align: right
			font-size: 30px
			color: $color-text-green_dark
			@include media-breakpoint-up(lg)
				display: none
			
						
					
				
				
			
			
			
			
			
			
		